<template>
    <div id="User_Profile" class="container-fluid p-0 m-0" style="min-height: 100vh; border-left: 4px solid white">
        <div class="row" v-if="loading">
            <div class="col-12">
                <li-spinner size="sm"/>
            </div>
        </div>
        
        <div class="row" v-if="!loading">
            <div class="col-12 pt-3">
                <button @click.stop="$router.back()"
                    class="btn btn-outline-primary btn-pill">
                    <img :src="arrow_left_icon" alt="" />
                    Regresar
                </button>
            </div>

            <div class="col-12 p-0 m-0 mt-3 pl-4">
                <p style="font-size: 28px;">
                    Información del conductor
                </p>
            </div>

            <div class="col-12 p-0 m-0 mt-3">
                <div class="row p-0 m-0">

                    <div class="col-12 col-lg-6 order-2 order-md-2 order-lg-1">
                        <div class="row">

                            <div class="col-12">
                                <p style="margin: 0px; font-size: 20px;">
                                    Nombre:
                                </p>
                                <span class="pl-3">
                                    {{ driver.first_name }} {{ driver.last_name }} {{ driver.second_surname}}
                                </span>
                                <hr style="border: 1px solid #008c9c;" class="m-0">
                            </div>

                            <div class="col-12 col-md-6 pt-2">
                                <p style="margin: 0px; font-size: 20px;">
                                    Telefono:
                                </p>
                                <span class="pl-3">
                                    {{ driver.phone }} 
                                </span>
                                <hr style="border: 1px solid #008c9c;" class="m-0">
                            </div>

                            <div class="col-12 col-md-6 pt-2">
                                <p style="margin: 0px; font-size: 20px;">
                                    Correo electrónico:
                                </p>
                                <span class="pl-3">
                                    {{ driver.email }} 
                                </span>
                                <hr style="border: 1px solid #008c9c;" class="m-0">
                            </div>

                        </div>
                    </div>

                    <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-md-center justify-content-lg-start p-0 m-0 py-sm-3 py-lg-0 order-1 order-md-1 order-lg-2">
                        <div style="max-width: 260px;">
                            <img :src="driver.picture ?? placeholder_image"  alt=""
                            class="img-fluid" style="border-radius: 50%; height: 260px; object-fit: scale-down"/>
                        </div>
                    </div>

                </div>  
            </div>

            <div class="col-12 col-md-6 mt-4">
                <button @click.stop="downloadReport"
                    class="btn btn-pill btn-outline-primary"
                    type="button">
                    Descargar reporte
                </button>
            </div>

            <div class="col-12">
                <div class="row">

                    <div class="col-12">
                        <p style="font-size: 24px; margin: 0px;">
                            Dirección: 
                        </p>
                    </div>

                    <div class="col-12 col-md-6 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Ciudad:
                        </p>
                        <span class="pl-3">
                            {{ driver.driver_address.length > 0 ? driver.driver_address[0].city : ''}} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-6 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Calle:
                        </p>
                        <span class="pl-3">
                            {{ driver.driver_address.length > 0 ? driver.driver_address[0].street : ''}} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-6 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Región:
                        </p>
                        <span class="pl-3">
                            {{ driver.driver_address.length > 0 ? driver.driver_address[0].region_name : ''}} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-6 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Municipio/Localidad:
                        </p>
                        <span class="pl-3">
                            {{ driver.driver_address.length > 0 ? driver.driver_address[0].municipality : ''}} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-6 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Código Postal:
                        </p>
                        <span class="pl-3">
                            {{ driver.driver_address.length > 0 ? driver.driver_address[0].postal_code : ''}} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>
                </div>
            </div>


            <!-- documentos recientes -->
            <div class="col-12 px-4 mt-4">
                <p>
                    Documentos
                </p>
                <hr class="border border-1 border-secondary w-100">
            </div>

            <div class="col-12 col-lg-6  px-4 my-2" v-for="(document, index) in driver.driver_document_types" :key="`document-${index}`">
                <p class="opensans-bold" style="font-size: 20px;">
                    {{ document.name}}
                </p>
                <div class="d-flex">
                    <div class="mx-2" v-if="document.item.length > 0" 
                        style="width: 250px;">
                        <p>
                            Vista frontal
                        </p>
                        <img @click.stop="preview_modal = true; current_url = document.item[0].frontal_image;"  
                            width="250" 
                            height="250" 
                            style="object-fit: scale-down;" 
                            :src="document.item[0].frontal_image" alt="">
                        <a class="btn btn-outline-primary btn-pill mt-3" :href="document.item[0].frontal_image" download>
                            Descargar
                        </a>
                    </div>

                    <div class="mx-2" v-if="document.item.length > 0 && document.item[0].hasOwnProperty('subsequent_image') && document.item[0].subsequent_image != null"
                        style="width: 250px;">
                        <p>
                            Reverso
                        </p>
                        <img  @click.stop="preview_modal = true; current_url = document.item[0].subsequent_image;"
                            width="250" 
                            height="250" 
                            style="object-fit: scale-down;" 
                            :src="document.item[0].subsequent_image" alt="">
                        <a class="btn btn-outline-primary btn-pill mt-3" :href="document.item[0].subsequent_image" download>
                            Descargar
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-12 pt-3 " v-if="documents_data.length > 0">
                <div class="row">
                    <div class="col-12">
                        <p style="font-size: 24px;">
                            Relación de documentos:
                        </p>
                    </div>

                    <div class="col-12" v-if="documents_data.length > 0">
                        <li-table :data="documents_data"
                            :allow_headers="documents_headers"
                            pagination_off
                            full>
                            <template v-slot:frontal_image="data">
                                <img :src="data.value" width="150px" height="150px" style="object-fit: scale-down;">
                            </template>
                            <template v-slot:subsequent_image="data">
                                <img :src="data.value" width="150px" height="150px" style="object-fit: scale-down;" v-if="data.value">
                            </template>
                            <template v-slot:expiration_date="data">
                                {{ new Date(data.value).toLocaleDateString()}}
                            </template>
                            <template v-slot:creation="data">
                                {{ new Date(data.value).toLocaleDateString()}}
                            </template>
                        </li-table>
                    </div>
                </div>
            </div>

            <div class="col-12 pt-3 " v-if="rentals_data.length > 0">
                <div class="row">
                    <div class="col-12">
                        <p style="font-size: 24px;">
                            Relación de rentas:
                        </p>
                    </div>

                    <div class="col-12" v-if="rentals_data.length > 0">
                        <li-table :data="rentals_data"
                            :allow_headers="rentals_headers"
                            pagination_off
                            full>

                            <template v-slot:color_name="data">
                                 <div class="d-flex justify-content-center align-items-center">
                                    <span>
                                        {{ data.item.color_name}}
                                    </span>
                                    <div style="margin-left: 15px; width: 15px; height: 15px; border: 1px solid white; border-radius: 50%;"
                                        :style="`background-color: #${data.item.hex}`">

                                    </div>
                                 </div>
                            </template>
                            <template v-slot:rent_discount="data">
                                 $ {{ formatAmount(data.item.rent_discount, 2)  }}
                            </template>
                            <template v-slot:rent_rate="data">
                                 $ {{ formatAmount(data.item.rent_rate, 2)  }}
                            </template>
                            <template v-slot:start_date="data">
                                <span>
                                        {{ new Date(data.item.start_date).toLocaleString() }}
                                </span>
                            </template>
                            <template v-slot:end_date="data">
                                <span>
                                        {{ data.item.end_date ? new Date(data.item.end_date).toLocaleString() : 'Actualidad' }}
                                </span>
                            </template>
                        </li-table>
                    </div>
                </div>
            </div>

            <div class="col-12" v-if="incidences_data.length > 0">
                <div class="row">
                    <div class="col-12">
                        <p style="font-size: 24px;">
                            Relación de incidencias:
                        </p>
                    </div>

                    <div class="col-12">
                        <li-table :data="incidences_data"
                            :allow_headers="incidences_headers"
                            pagination_off
                            full>
                            <template v-slot:date_incident="data">
                                {{ new Date(data.value).toLocaleDateString()}}
                            </template>
                        </li-table>
                    </div>
                </div>
            </div>

            
        </div>

        <!-- modal preview--->
        <li-modal :show_modal="preview_modal" :max_width="750" class="px-3">
            <div class="col-12">
                <img width="700" height="700" style="object-fit: scale-down;" :src="current_url" alt="">
            </div>

            <div class="col-12 d-flex flex-end">
                <button @click.stop="preview_modal = false; current_url = false;"
                    class="btn btn-pill btn-outline-primary"
                    type="button">
                    Cerrar
                </button>
            </div>
        </li-modal>
    </div>
</template>


<script>
    //icons
    import arrow_left_icon from 'ASSETS/icons/arrow_left.png'
    import placeholder_image from 'ASSETS/images/placeholder-image.png'
    
    //helpers
    import { mapGetters } from 'vuex'

    export default {
        data(){
            return {
                arrow_left_icon,
                placeholder_image,
                loading: false,
                preview_modal: false,
                current_url: null,
                incidences_headers: [
                    {key: 'type', label: 'Tipo'},
                    {key: 'plate', label: 'Placas'},
                    {key: 'model_name', label: 'Modelo'},
                    {key: 'incident_status', label: 'status'},
                    {key: 'user_responsible_name', label: 'Reparó'},
                    {key: 'description', label: 'Descripción'},
                    {key: 'date_incident', label: 'Fecha'}
                ],
                incidences_data: [],
                rentals_headers: [
                    { key: 'model_name', label: 'Modelo'},
                    { key: 'brand_name', label: 'Marca'},
                    { key: 'vehicle_type_name', label: 'Tipo'},
                    { key: 'color_name', label: 'Color'},
                    { key: 'rent_discount', label: 'Descuento'},
                    { key: 'rent_rate', label: 'Renta'},
                    { key: 'start_date', label: 'Desde'},
                    { key: 'end_date', label: 'Hasta'}
                ],
                rentals_data: [],
                documents_headers: [
                    {key: 'name', label: 'Documento'},
                    {key: 'frontal_image', label: 'Frente'},
                    {key: 'subsequent_image', label: 'Reverso'},
                    {key: 'expiration_date', label: 'Expiración'},
                    {key: 'creation', label: 'Fecha de subida'}
                ],
                documents_data:[]
            };
        },
        methods: {
            async downloadReport() {
                window.open(this.report, '_blank')
            }
        },
        computed: {
            ...mapGetters({
                driver: 'drivers/getDriver',
                report: 'drivers/getReportUrl'
            })
        },
        async created(){
            try {

                this.loading = true
                //información del conductor
                let id_driver = this.$route.params.id;
                await this.$store.dispatch('drivers/viewDriver', {id_driver: id_driver, only_last: false});
                await this.$store.dispatch('drivers/report', {id_driver: id_driver});
                console.log('driver', this.driver)

                this.rentals_data = this.driver.rentals.map( rent => {
                    return {
                        model_name: rent.model_name,
                        brand_name: rent.brand_name,
                        vehicle_type_name: rent.vehicle_type_name,
                        color_name: rent.color_name,
                        rent_discount: rent.rent_discount,
                        rent_rate: 1200,
                        start_date: rent.start_date,
                        end_date: rent.end_date
                    }
                });

                this.incidences_data = this.driver.incidents ?? []
                this.documents_data = this.driver.driver_documents ?? []

                this.loading = false
                
            }
            catch(error) {
                this.loading = false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>